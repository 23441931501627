export default {
  "common": {
    "network_error": "Network error",
    "warning": "warning",
    "need_reconnect_wallet": "Please reconnect your wallet.",
    "web3_not_available": "Web3 is not available. Please check your connection.",
    "copied_success": "Successfully copied to clipboard",
    "copied_failed": "An error occurred copying to Clipboard",
    "home": "Home",
    "news": "News",
    "navigation": "Navigation",
    "marketplace": "Marketplace",
    "create": "Create",
    "save_success": "Successfully saved",
    "date_saving": "Saving date",
    "img_format_error": "Image format is invalid",
    "img_large_error": "The image cannot be larger than {0}MB",
    "check_in_failed":
      "An error occurred checking in. Please try again later.",
    "already_check_in":
      "You have already checked in today.",
    "check_in_success": "Successfully checked in",
    "img_upload_error":
      "An error occurred uploading the image. Please try again later.",
  },
  "home": {
    "bjx_buy_success":
      "Successfully bought {count} BJXStar use {amount} {coin}",
  },
  "create": {
    "category_required": "Category is required",
    "maxSupply_required": "Nft max supply is required",
    "maxSupply_invalid": "Nft max supply must be a number",
    "availableSupply_required":
      "Nft available supply is required",
    "availableSupply_invalid":
      "Nft available supply must be a number",
    "availableSupply_more_than_available":
      "Nft available supply must be less than or equal current available supply",
    "initialQuantity_required": "Initial quantity is required",
    "initialQuantity_invalid": "Initial quantity must be a number",
    "initialQuantity_more_than":
      "Initial quantity must be less than or equal to maxSupply*20%",
    "initialPrice_required": "Initial price is required",
    "initialPrice_invalid": "Initial price must be a number",
    "title_required": "Title is required",
    "description_required": "Description is required",
    "image_required": "Cover image is required",
    "keyword_required": "At least one keyword is required.",
    "content_pub_required": "Open article content is required",
    "save_uri_failed":
      "An error occurred saving the URI. Please check your connection.",
    "nft_mint_failed": "An error occurred minting the NFT. Please check your connection.",
    "no_permission_update": "You do not have permission to update this NFT.",
    "data_not_modified":
      "The data you are trying to update is not modified.",
    "nft_voting": "Cannot update a voting NFT."
  },
  "news-detail": {
    "have_no_nft":
      "You do not have any this NFTs.",
    "unlock_success": "Unlock success.",
    "unlock_failed": "Unlock failed.",
    "mbd_approve_failed":
      "An error occurred approving the MBD. Please check your wallet.",
    "nft_mint_failed": "An error occurred minting the NFT. Please check your connection.",
    "nft_mint_success": "NFT minted successfully.",
    "order_create_failed":
      "Order create failed.",
    "order_create_success": "Order create success.",
    "order_price_required":
      "Order price is required.",
    "order_quantity_required":
      "Order quantity is required.",
    "order_price_invalid":
      "Order price is invalid.",
    "order_quantity_invalid":
      "Order quantity is invalid.",
    "stake_count_required":
      "Stake count is required.",
    "stake_count_invalid":
      "Stake count is invalid.",
    "stake_success":
      "Stake success.",
    "stake_failed":
      "Stake failed.",
    "retrieve_count_required":
      "Retrieve count is required.",
    "retrieve_count_invalid":
      "Retrieve count is invalid.",
    "retrieve_success":
      "Retrieve success.",
    "retrieve_failed":
      "Retrieve failed.",
    "retrieve_unable":
      "The retrieve condition is not met.",
    "get_blind_box_failed":
      "An error occurred getting the blind box. Please try again later.",
    "submit_success":
      "Submit success.",
    "update_unable":
      "No permission to update.",
    "order_cancel_success":
      "Order cancel success.",
    "swap_success":
      "Swap success.",
    "vote_success":
      "Vote successfully.",
    "execute_success":
      "Execute successfully.",
    "vote_already":
      "You have already voted.",
  },
  "user": {
    "profile_save_success": "Profile saved successfully.",
  }
}
