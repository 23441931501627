export default {
  "common": {
    "warning": "警告",
    "need_reconnect_wallet": "請重新連結您的錢包。",
    "web3_not_available": "Web3 is not available. Please check your connection.",
    "copied_success": "Successfully copied to clipboard",
    "copied_failed": "An error occurred copying to Clipboard",
    "home": "首页",
  }
}
